<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        For each of the molecules below, determine the oxidation state for every atom in the
        compound. Note that none of the oxygen atoms are in the peroxide or superoxide form.
      </p>

      <p class="mb-0 pb-0">
        <chemical-latex content="a) CuCO3" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1A"
            class="pb-3"
            prepend-text="Cu:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1B"
            class="pb-3"
            prepend-text="C:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS1C"
            class="pb-3"
            prepend-text="O:"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex content="b) SF6" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS2A"
            class="pb-3"
            prepend-text="S:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS2B"
            class="pb-3"
            prepend-text="F:"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex content="c) IF" />
      </p>
      <v-row class="pl-7 mb-2">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS3A"
            class="pb-3"
            prepend-text="I:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS3B"
            class="pb-3"
            prepend-text="F:"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-0 pb-0">
        <chemical-latex content="d) K2S2O7" />
      </p>
      <v-row class="pl-7">
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS4A"
            class="pb-3"
            prepend-text="K:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS4B"
            class="pb-3"
            prepend-text="S:"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <calculation-input
            v-model="inputs.OS4C"
            class="pb-3"
            prepend-text="O:"
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question227',
  components: {CalculationInput, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        OS1A: null,
        OS1B: null,
        OS1C: null,
        OS2A: null,
        OS2B: null,
        OS3A: null,
        OS3B: null,
        OS4A: null,
        OS4B: null,
        OS4C: null,
      },
    };
  },
};
</script>
